/*!

 =========================================================
 * Now UI Dashboard - v1.5.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'dashboard/core/variables';
@import 'dashboard/core/mixins';

// Plugins CSS
@import "dashboard/core/plugins/plugin-animate-bootstrap-notify";
@import "dashboard/core/plugins/plugin-perfect-scrollbar";

// Core CSS
@import "dashboard/core/buttons";
@import "dashboard/core/inputs";
@import "dashboard/core/typography";
@import "dashboard/core/misc";
@import "dashboard/core/checkboxes-radio";

// components
@import "dashboard/core/navbar";
@import "dashboard/core/page-header";
@import "dashboard/core/dropdown";
@import "dashboard/core/alerts";
@import "dashboard/core/images";
@import "core/nucleo-outline";
@import "dashboard/core/tables";
@import "dashboard/core/sidebar-and-main-panel";
@import "dashboard/core/footers";
@import "dashboard/core/fixed-plugin";

// cards
@import "dashboard/core/cards";
@import "dashboard/core/cards/card-plain";
@import "dashboard/core/cards/card-chart";
@import "dashboard/core/cards/card-user";
@import "dashboard/core/cards/card-map";

@import "dashboard/core/responsive";
